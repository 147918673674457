import { isLocalhost } from 'mmfintech-commons'

const host = typeof window !== 'undefined' ? window.location.host : ''

const isDevelopment = host.indexOf('-dev.') > -1
const isSandbox = host.indexOf('-sandbox.') > -1
export const isProduction = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://checkout-dev.xchange-payments.com',
  // backendForLocalhost: 'https://checkout-sandbox.xchange-payments.com',
  languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja', 'ko'] // NO,TR
}

export default settings
