import styled from 'styled-components'

export const SelectMethodWrapper = styled.div`
  margin: 3rem 0;
`

export const SelectMethodTitle = styled.div`
  margin-bottom: 2.5rem;
  text-align: center;

  color: #454a54;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.1rem;
`

export const SelectMethodEmpty = styled.div`
  color: #808080;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`

export const SelectMethodContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  &.use-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }
`
