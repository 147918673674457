import parse from 'html-react-parser'
// noinspection ES6CheckImport
import dompurify from 'dompurify'

import { SuccessMessageBody, SuccessMessageTitle, SuccessMessageWrapper } from './PreviewMessage.styled'

import { tr } from 'mmfintech-commons'
import { SuccessIcon } from '../../../icons'

export const PreviewMessage = ({ result }) => {
  const message = result.replace(/\r?\n/g, '<br/>').replace(/\\n/g, '<br/>')

  return (
    <SuccessMessageWrapper>
      <SuccessIcon />
      <SuccessMessageTitle>{tr('FRONTEND.DEPOSIT.RESULT.SUCCESS', 'Success')}</SuccessMessageTitle>
      <SuccessMessageBody>{parse(dompurify.sanitize(message))}</SuccessMessageBody>
    </SuccessMessageWrapper>
  )
}
