import { shallowEqual, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { TailSpin } from 'react-loader-spinner'
import { CheckoutButtonsWrapper } from './Checkout.styled'

import { tr } from 'mmfintech-commons'

export const ButtonsFooter = ({ disabled, onSubmit, onCancel, showCancel }) => {
  const { checkoutPayFetching } = useSelector(
    ({ checkout: { checkoutPayFetching } }) => ({
      checkoutPayFetching
    }),
    shallowEqual
  )

  return (
    <CheckoutButtonsWrapper>
      <Button
        type='button'
        color='primary'
        text={
          checkoutPayFetching ? (
            <TailSpin color='white' strokeWidth='4px' height='3rem' />
          ) : (
            tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
          )
        }
        disabled={disabled}
        onClick={onSubmit}
        data-test='button-continue'
      />

      {showCancel ? (
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          disabled={checkoutPayFetching}
          onClick={onCancel}
          data-test='button-cancel'
        />
      ) : null}
    </CheckoutButtonsWrapper>
  )
}
