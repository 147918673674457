import { Input, InputError, InputLabel } from 'mmfintech-portal-commons'
import { InputDateWrapper } from './InputDateFields.styled'
import { monthOptions } from 'mmfintech-commons'
import cn from 'classnames'

interface InputDateFieldsProps {
  label?: string
  value: Date
  setValue: (value: Date) => void
  error?: string
  setError?: (error: string) => void
  required?: boolean
}

export const InputDateFields = (props: InputDateFieldsProps) => {
  const { label, value, setValue, error, setError, required } = props

  const dayOptions = () => {
    const date = value ? value : new Date()
    const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    const res = []
    for (let i = 1; i <= days; i++) {
      res.push({ value: String(i), label: `${i < 10 ? '0' : ''}${i}` })
    }
    return res
  }

  // const monthOptions = () => {
  //   const res = []
  //   for (let i = 1; i <= 12; i++) {
  //     res.push({ value: String(i - 1), label: `${i < 10 ? '0' : ''}${i}` })
  //   }
  //   return res
  // }

  const yearOptions = () => {
    const date = new Date()
    const min = date.getFullYear() - 120
    const res = []
    for (let i = date.getFullYear() - 16; i > min; i--) {
      res.push({ value: String(i), label: String(i) })
    }
    return res
  }

  const handleDayChange = (day: string) => {
    typeof setError === 'function' && setError(null)
    const date = value ? value : new Date()
    setValue(new Date(date.getFullYear(), date.getMonth(), Number(day)))
  }

  const handleMonthChange = (month: string) => {
    typeof setError === 'function' && setError(null)
    const date = value ? value : new Date()
    setValue(new Date(date.getFullYear(), Number(month) - 1, date.getDate()))
  }

  const handleYearChange = (year: string) => {
    typeof setError === 'function' && setError(null)
    const date = value ? value : new Date()
    setValue(new Date(Number(year), date.getMonth(), date.getDate()))
  }

  return (
    <>
      <InputLabel label={label} required={required} requiredPosition='right' className={cn({ required })} />
      <InputDateWrapper>
        <Input
          type='select'
          name='day'
          options={dayOptions()}
          value={value ? String(value.getDate()) : null}
          onChange={(_name: string, value: string) => handleDayChange(value)}
          isSearchable={false}
          placeholder='DD'
          hideErrorLine
        />
        <Input
          type='select'
          name='month'
          options={monthOptions()}
          value={value ? value.getMonth() + 1 : null}
          onChange={(_name: string, value: string) => handleMonthChange(value)}
          isSearchable={false}
          placeholder='MM'
          hideErrorLine
        />
        <Input
          type='select'
          name='year'
          options={yearOptions()}
          value={value ? String(value.getFullYear()) : null}
          onChange={(_name: string, value: string) => handleYearChange(value)}
          isSearchable={false}
          placeholder='YYYY'
          hideErrorLine
        />
      </InputDateWrapper>
      <InputError error={error} hideErrorLine={false} hasIcon={false} />
    </>
  )
}
