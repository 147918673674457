import styled from 'styled-components'

export const SelectBankItemStyled = styled.div<{ buttonVisible: boolean }>`
  display: flex;
  align-items: stretch; // make children stretch to fill the container vertically
  justify-content: space-between;
  height: 7rem;

  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;

  &.maintenance {
    cursor: not-allowed;
  }

  .content-wrapper {
    transition: max-width 0.3s ease;
    max-width: ${props => (props.buttonVisible ? '80%' : '100%')};
    display: flex;
    flex-grow: 1; // allow this element to grow and fill the remaining space
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;

    .icon {
      display: flex;
      margin-right: 1.2rem;
      max-width: 5rem;
      max-height: 5rem;
    }

    .icon > img {
      @media (max-width: 480px) {
        width: 3.2rem;
      }

      @media (max-width: 240px) {
        width: 2.6rem;
      }
    }
  }

  &.selected {
    background-color: #f2f2f5; //rgba(27, 82, 156, 0.3);
    border: 2px solid #f6d34c; //#1b529c;
    border-radius: 4px;
  }

  .text {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.4rem;
    @media (max-width: 480px) {
      font-size: 1.2rem;
    }

    .caption {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: normal;

      @media (max-width: 480px) {
        font-size: 1.4rem;
      }
    }

    .warning {
      color: #ee2b2b;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 100%;
    }
  }
`

export const ButtonWrapper = styled.div<{ visible: boolean; disabled: boolean }>`
  transition: min-width 0.3s ease;
  max-width: ${props => (props.visible ? '100%' : '0')};

  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  min-width: ${({ visible }) => (visible ? '10rem' : '0')};

  background-color: ${({ disabled }) => (disabled ? 'rgba(27, 82, 156, 0.5)' : '#F6D34C')};
  // background-color: ${({ disabled }) => (disabled ? `rgba(27, 82, 156, 0.5)` : `rgb(27, 82, 156)`)};
  // &:hover {
  //   background-color: ${({ disabled }) => (disabled ? `rgba(27, 82, 156, 0.5)` : `rgba(27, 82, 156, 0.9)`)};
  // }
`
