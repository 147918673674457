import styled from 'styled-components'

export const InputDateWrapper = styled.div`
  display: inline-grid;
  grid-gap: 1rem;
  //grid-template-columns: 110px 160px 125px;
  grid-template-columns: 2fr 3fr 2.5fr;
  font-size: 1.4rem;
  width: 100%;

  .input-wrapper {
    margin-bottom: 0;
  }

  .spacer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`
