import { ReactNode } from 'react'

type LabelValueProps = {
  label?: string
  value?: ReactNode
  dataTest?: string
}

export const LabelValue = ({ label, value, dataTest }: LabelValueProps) => {
  if (value) {
    return (
      <>
        <div className='label'>{label}</div>
        <div data-test={dataTest}>{value}</div>
      </>
    )
  }
  return null
}
