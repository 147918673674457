import { tr } from 'mmfintech-commons'

import { PreviewPayId } from './PreviewPayId'
import { PreviewContainer } from '../PreviewBankwire/PreviewBankwire.styled'
import { InstructionsContainer } from './PreviewInstructions.styled'

import {
  BaseInstructionsResult,
  FawryPayInstructionsResult,
  FlutterwaveMobileAppInstructionsResult,
  PaymentMethodEnum,
  PaymentSessionData
} from 'mmfintech-commons-types'

interface PreviewInstructionsProps {
  result: BaseInstructionsResult
  session?: PaymentSessionData
  startPolling: () => void
}

export const PreviewInstructions = ({ result, startPolling }: PreviewInstructionsProps) => {
  // const { successUrl } = session || {}
  const { paymentMethod } = result || {}

  // const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  /*const handleRedirect = () => {
    void dispatch(actions.common.eventTrack(sessionId, 'redirected_back_to_merchant'))
    window.location.href = successUrl
  }*/

  /*const RedirectButton = () =>
    successUrl ? (
      <div className='buttons'>
        <Button
          type='button'
          color='primary'
          onClick={handleRedirect}
          text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
          data-test='button-back'
        />
      </div>
    ) : null*/

  if (paymentMethod === PaymentMethodEnum.PAY_ID) {
    startPolling()
    return (
      <PreviewContainer>
        <PreviewPayId result={result} />
        {/*<RedirectButton />*/}
      </PreviewContainer>
    )
  }

  if (paymentMethod === PaymentMethodEnum.FAWRY_PAY) {
    const { instructions } = result as FawryPayInstructionsResult
    return (
      <>
        <InstructionsContainer>{instructions}</InstructionsContainer>
        {/*<RedirectButton />*/}
      </>
    )
  }

  if (paymentMethod === PaymentMethodEnum.M_PESA || paymentMethod === PaymentMethodEnum.MOBILE_MONEY) {
    const { phoneNumber } = result as FlutterwaveMobileAppInstructionsResult
    return (
      <>
        <InstructionsContainer>
          {tr(
            'CHECKOUT.INSTRUCTIONS.MPESA',
            'You will receive a prompt on mobile number {{phone_number}} to enter your MPESA service PIN to authorize your payment request.',
            { phone_number: phoneNumber }
          )}
        </InstructionsContainer>
        {/*<RedirectButton />*/}
      </>
    )
  }

  if (paymentMethod === PaymentMethodEnum.MOBILE_MONEY) {
    const { phoneNumber } = result as FlutterwaveMobileAppInstructionsResult
    return (
      <>
        <InstructionsContainer>
          {tr(
            'CHECKOUT.INSTRUCTIONS.MOBILE_MONEY',
            'You will receive a prompt on mobile number {{phone_number}} to enter your Mobile Money service PIN to authorize your payment request.',
            { phone_number: phoneNumber }
          )}
        </InstructionsContainer>
        {/*<RedirectButton />*/}
      </>
    )
  }

  return null
}
