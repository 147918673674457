// noinspection SpellCheckingInspection

import styled from 'styled-components'

import logoPix from '../../assets/methods/pix.png'
import logoUpi from '../../assets/methods/upi.png'
import logoJeton from '../../assets/methods/jeton.svg'
import logoKhipu from '../../assets/methods/khipu.png'
import logoCrypto from '../../assets/methods/crypto.svg'
import logoBoleto from '../../assets/methods/boleto.png'
import logoInterac from '../../assets/methods/interac.png'
import logoPecFlash from '../../assets/methods/pec-flash.png'
import logoBTVoucher from '../../assets/methods/btvoucher.png'
import logoSafetyPay from '../../assets/methods/safetypay.png'
import logoCreditCard from '../../assets/methods/credit-card.png'
import logoBankTransfer from '../../assets/methods/bank-transfer.svg'
import logoPagoEfectivo from '../../assets/methods/pago-efectivo.png'
import logoKingdomWallet from '../../assets/methods/kingdom-wallet.png'
import logoIndianNetBanking from '../../assets/methods/indian-bank.png'
import logoInstantBankTransfer from '../../assets/methods/instant-bank-transfer.svg'
import logoPayID from '../../assets/methods/pay-id.png'

import logoBcpCash from '../../assets/methods/bcp.svg'
import logoCellPowerCash from '../../assets/methods/cell_power.svg'
import logoEfectyCash from '../../assets/methods/efecty.svg'
import logoYape from '../../assets/methods/yape.png'

export const MethodButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;

  width: 100%;
  max-width: 16rem;
  padding: 0 1.5rem 2rem;

  .use-grid & {
    max-width: unset;
  }

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: center;

  &.selected {
    color: #454a54;
  }

  :hover {
    color: #000000;
  }
`

export const MethodButtonImage = styled.span`
  position: relative;
  width: 14rem;
  height: 10rem;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(30, 99, 255, 0.05);
  background-position: center center;
  background-repeat: no-repeat;

  &.selected {
    border: none;
  }

  //prettier-ignore
  &.image-pix {
    background-image: url("${logoPix}");
  }
  //prettier-ignore
  &.image-upi {
    background-image: url("${logoUpi}");
  }
  //prettier-ignore
  &.image-jeton,
  &.image-jeton_cash {
    background-image: url("${logoJeton}");
  }
  //prettier-ignore
  &.image-khipu {
    background-image: url("${logoKhipu}");
  }
  //prettier-ignore
  &.image-instant_crypto {
    background-image: url("${logoCrypto}");
  }
  //prettier-ignore
  &.image-boleto {
    background-image: url("${logoBoleto}");
  }
  //prettier-ignore
  &.image-interac {
    background-image: url("${logoInterac}");
  }
  //prettier-ignore
  &.image-pec_flash {
    background-image: url("${logoPecFlash}");
  }
  //prettier-ignore
  &.image-btvoucher {
    background-image: url("${logoBTVoucher}");
  }
  //prettier-ignore
  &.image-safetypay_bank,
  &.image-safetypay_cash {
    background-image: url("${logoSafetyPay}");
  }
  //prettier-ignore
  &.image-credit_card {
    background-image: url("${logoCreditCard}");
  }
  //prettier-ignore
  &.image-bankwire {
    background-image: url("${logoBankTransfer}");
  }
  //prettier-ignore
  &.image-pago_efectivo_bank,
  &.image-pago_efectivo_cash {
    background-image: url("${logoPagoEfectivo}");
  }
  //prettier-ignore
  &.image-kingdom_wallet {
    background-image: url("${logoKingdomWallet}");
  }
  //prettier-ignore
  &.image-indian_net_banking {
    background-image: url("${logoIndianNetBanking}");
  }
  //prettier-ignore
  &.image-instant_bank_transfer,
  &.image-direct_instant_bank_transfer,
  &.image-community_instant_bank_transfer {
    background-image: url("${logoInstantBankTransfer}");
  }
  //prettier-ignore
  &.image-bcp_cash {
    background-image: url("${logoBcpCash}");
  }
  //prettier-ignore
  &.image-cell_power_cash {
    background-image: url("${logoCellPowerCash}");
  }
  //prettier-ignore
  &.image-efecty_cash {
    background-image: url("${logoEfectyCash}");
  }
  //prettier-ignore
  &.image-yape {
    background-image: url("${logoYape}");
  }
  //prettier-ignore
  &.image-pay_id {
    background-image: url("${logoPayID}");
    background-size: 35%;
  }

  &.icon {
    width: 4.2rem;
    height: 3rem;
    padding: 0;
    margin: 0;
    background-position: right center;
    background-color: transparent;
    background-size: contain;
    border-radius: 0;
  }
`

export const MethodButtonChecked = styled.span`
  position: absolute;
  top: 0;
  right: -10px;
  transform: translate(5px, -15px);
`
