import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { Input } from 'mmfintech-portal-commons'
import { SelectBank } from '../../components'

import { tr } from 'mmfintech-commons'
import { useFilteredBanks } from 'mmfintech-checkout-commons'

import { SupportedBank } from 'mmfintech-commons-types'

export const SelectBankStep = ({ countryCode, formValues, onSubmit, logEvent }) => {
  const { banksCount, checkoutBanksFetching } = useSelector(
    ({ checkout: { banksCount, checkoutBanksFetching } }: any) => ({
      banksCount,
      checkoutBanksFetching
    }),
    shallowEqual
  )

  const [eventLogged, setEventLogged] = useState(false)

  const filteredBanks = useFilteredBanks((bank: SupportedBank) => handleSelectBank(bank ? bank.bankChoiceId : ''), 0)

  const logBankSearchFocus = () => logEvent('bank_search_focus', undefined, true)
  const logBankSearchType = () => {
    if (!eventLogged) {
      logEvent('bank_search_type', undefined, true)
      setEventLogged(true)
    }
  }

  const handleSelectBank = (bankCode: string) => {
    formValues.setValue('bankChoiceId', bankCode)
    logEvent('bank_selected', bankCode)
  }

  return (
    <>
      {banksCount > 10 ? (
        <div className='search-container'>
          <Input
            key='bank-search'
            type='text'
            name='bank-search'
            maxLength={50}
            value={filteredBanks.bankSearchText}
            onChange={(_name: string, value: string) => {
              filteredBanks.setBankSearchText(value)
              logBankSearchType()
            }}
            onFocus={logBankSearchFocus}
            placeholder={
              countryCode === 'DEU'
                ? tr('CHECKOUT.PAYMENT.BANK_SEARCH_DEU', 'Start typing your bank name or BLZ')
                : tr('CHECKOUT.PAYMENT.BANK_SEARCH', 'Start typing your bank name')
            }
            hideErrorLine
            autoComplete='off'
            data-test='bank-search-input'
          />
        </div>
      ) : null}

      <SelectBank
        selected={formValues.getValue('bankChoiceId')}
        banks={filteredBanks}
        loading={checkoutBanksFetching}
        logEvent={logEvent}
        handleSubmit={onSubmit}
      />
    </>
  )
}
