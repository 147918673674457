import { ReactNode } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import cn from 'classnames'

import { ThreeCircles } from 'react-loader-spinner'
import { SelectBankItemStyled, ButtonWrapper } from './SelectBankItem.styled'

import { tr } from 'mmfintech-commons'
import { ArrowRight } from '../../icons'

type SelectBankItemProps = {
  icon?: ReactNode
  caption?: string
  description?: string
  loading?: boolean
  selected?: boolean
  maintenance?: boolean
  onClick: () => void
  onSubmit: () => void
}

export const SelectBankItem = ({
  caption,
  description,
  icon,
  loading,
  selected,
  maintenance,
  onClick,
  onSubmit,
  ...rest
}: SelectBankItemProps) => {
  const { checkoutPayFetching } = useSelector(
    ({ checkout: { checkoutPayFetching } }) => ({
      checkoutPayFetching
    }),
    shallowEqual
  )
  return (
    <SelectBankItemStyled
      className={cn('custom-radio', { selected, maintenance })}
      buttonVisible={selected}
      onClick={selected ? onSubmit : onClick}
      {...rest}>
      <div className='content-wrapper'>
        <span className='icon'>{icon}</span>
        <span className='text'>
          <span className='caption'>{caption}</span>
          <span>{description}</span>
          {maintenance ? (
            <span className='warning'>{tr('CHECKOUT.VOUCHER.BANKS.MAINTENANCE', 'Under maintenance')}</span>
          ) : null}
        </span>
      </div>
      <ButtonWrapper disabled={checkoutPayFetching} visible={selected} className='button-wrapper'>
        {loading ? <ThreeCircles width='3.5rem' height='3.5rem' color='#55419b' /> : <ArrowRight color='#55419b' />}
      </ButtonWrapper>
    </SelectBankItemStyled>
  )
}
