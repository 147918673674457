import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { SuccessIcon } from '../../icons'
import { ThunkDispatch } from 'redux-thunk'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { ReportProblemStyled, ReportProblemSuccess, ReportProblemTitle } from './ReportProblem.styled'

import { tr, useFormValues } from 'mmfintech-commons'
import { actions, useSessionId } from 'mmfintech-checkout-commons'

export const ReportProblem = ({ visible, onClose }) => {
  const [succeeded, setSucceeded] = useState(false)

  const { reportProblemError, reportProblemFetching } = useSelector(
    ({ checkout: { reportProblemError, reportProblemFetching } }) => ({
      reportProblemError,
      reportProblemFetching
    }),
    shallowEqual
  )

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { sessionId } = useSessionId()

  const formValues = useFormValues({
    problem: { required: true },
    description: { validation: 'safe-string' }
  })

  const updateRequired = (value: string): void => {
    formValues.setError('description', null)
    formValues.setRequired('description', value === 'Other')
  }

  const handleSubmit = () => {
    updateRequired(formValues.getValue('problem'))
    if (formValues.areValid()) {
      void dispatch(actions.checkout.submitProblem(sessionId, formValues.prepare(), () => setSucceeded(true)))
    }
  }

  const problems = () => [
    { value: 'Transaction Failure', label: tr('CHECKOUT.PROBLEM.TYPES.FAILURE', 'Transaction Failure') },
    { value: 'Interface Issue', label: tr('CHECKOUT.PROBLEM.TYPES.INTERFACE_ISSUE', 'Interface Issue') },
    { value: 'Information Discrepancy', label: tr('CHECKOUT.PROBLEM.TYPES.DISCREPANCY', 'Information Discrepancy') },
    { value: 'Slow Response', label: tr('CHECKOUT.PROBLEM.TYPES.SLOW_RESPONSE', 'Slow Response') },
    { value: 'Security Concern', label: tr('CHECKOUT.PROBLEM.TYPES.SECURITY', 'Security Concern') },
    {
      value: 'Login/Authentication Problem',
      label: tr('CHECKOUT.PROBLEM.TYPES.LOGIN', 'Login/Authentication Problem')
    },
    { value: 'Other', label: tr('CHECKOUT.PROBLEM.TYPES.OTHER', 'Other') }
  ]

  useEffect(() => {
    if (!visible) {
      dispatch(actions.checkout.cleanupProblem)
      setSucceeded(false)
    }
  }, [visible])

  return (
    <ReportProblemStyled>
      {succeeded ? (
        <>
          <ReportProblemSuccess>
            <SuccessIcon />
            <div>{tr('CHECKOUT.PROBLEM.SUCCESS', 'Your problem was successfully reported')}</div>
          </ReportProblemSuccess>

          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.OK', 'OK')}
            onClick={onClose}
            data-test='button-submit'
          />
        </>
      ) : (
        <>
          <ReportProblemTitle>{tr('CHECKOUT.PROBLEM.TITLE', 'Report a Problem')}</ReportProblemTitle>

          <Input
            type='select'
            placeholder={tr('CHECKOUT.PROBLEM.TYPE_PLACEHOLDER', 'Select')}
            label={tr('CHECKOUT.PROBLEM.TYPE_LABEL', 'Problem')}
            {...formValues.registerInput('problem', updateRequired)}
            options={problems()}
            isSearchable={false}
            data-test='problem'
          />

          <Input
            type='textarea'
            label={tr('CHECKOUT.PROBLEM.DESCRIPTION_LABEL', 'Describe the Problem')}
            {...formValues.registerInput('description')}
            data-test='description'
            rows={5}
          />

          <ErrorDisplay error={reportProblemError} />

          <Button
            type='button'
            color='primary'
            text={tr('CHECKOUT.PROBLEM.BUTTON_SUBMIT', 'Submit')}
            onClick={handleSubmit}
            loading={reportProblemFetching}
            data-test='button-submit'
          />
        </>
      )}
    </ReportProblemStyled>
  )
}
