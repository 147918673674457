import { shallowEqual, useSelector } from 'react-redux'

import { Input } from 'mmfintech-portal-commons'
import { CheckoutInputWrapper } from './Checkout.styled'

import { fixDateOnly, getCountryAlpha2, isValidArray, tr } from 'mmfintech-commons'

import { SupportedBank } from 'mmfintech-commons-types'
import { InputDateFields } from '../../components'

export const AdditionalFieldsStep = ({ countryCode, paymentMethod, fields, formValues }) => {
  const { banks } = useSelector(
    ({ checkout: { banks } }: any) => ({
      banks
    }),
    shallowEqual
  )

  const isInterac = () => paymentMethod === 'INTERAC'

  return (
    <>
      {isValidArray(fields)
        ? fields.map((item, index) => {
            const { name, type, defaultLabel, translationKey, required, sensitive, options } = item

            if (name === 'bankChoiceId') {
              return null
            }

            if (name === 'swiftCode' && isInterac()) {
              return (
                <CheckoutInputWrapper key={index}>
                  <Input
                    type='select'
                    data-test='interac-bank-select'
                    options={banks?.map((bank: SupportedBank) => {
                      const { bankId, name, nameEnglish } = bank
                      return { value: bankId, label: nameEnglish || name }
                    })}
                    label={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.BANK', 'Bank')}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                    menuPlacement='top'
                  />
                </CheckoutInputWrapper>
              )
            }

            if (name === 'phone') {
              return (
                <CheckoutInputWrapper key={index}>
                  <Input
                    type='phone'
                    data-test={`${name}-input`}
                    label={tr(translationKey, defaultLabel)}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                    autoComplete='off'
                    {...(isInterac()
                      ? {
                          country: 'ca',
                          onlyCountries: ['ca'],
                          countryCodeEditable: false,
                          disableDropdown: true
                        }
                      : {
                          country: getCountryAlpha2(countryCode)?.toLowerCase()
                        })}
                  />
                </CheckoutInputWrapper>
              )
            }

            if (name === 'documentId' && paymentMethod === 'PIX') {
              return (
                <Input
                  key={index}
                  type={type || 'text'}
                  label={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.CPF_CNPJ', tr(translationKey, defaultLabel))}
                  data-test='pix-document-id-input'
                  {...formValues.registerShort(name)}
                  onChange={(name: string, value: string) => formValues.setValue(name, value.replace(/[ ./-]/g, ''))}
                  required={required}
                  hideErrorLine
                  autoComplete='off'
                />
              )
            }

            if (type.toLowerCase() === 'date') {
              return (
                <CheckoutInputWrapper key={index}>
                  <InputDateFields
                    label={tr(translationKey, defaultLabel)}
                    value={formValues.getValue(name) ? new Date(formValues.getValue(name)) : null}
                    setValue={value => formValues.setValue(name, fixDateOnly(value))}
                    required={required}
                    error={formValues.getError(name)}
                    setError={value => formValues.setError(name, value)}
                  />
                </CheckoutInputWrapper>
                /*
                                <CheckoutInputWrapper key={index}>
                                  <Input
                                    type='date'
                                    data-test={`${name}-input`}
                                    label={tr(translationKey, defaultLabel)}
                                    {...formValues.registerShort(name)}
                                    onChange={(name: string, value: any) => {
                                      formValues.setValue(name, fixDateOnly(value))
                                    }}
                                    required={required}
                                    hideErrorLine
                                    autoComplete='off'
                                  />
                                </CheckoutInputWrapper>
                */
              )
            }

            if (type.toLowerCase() === 'select') {
              const extractedOptions =
                typeof options === 'object' ? Object.entries(options).map(([value, label]) => ({ value, label })) : []
              return (
                <CheckoutInputWrapper key={index}>
                  <Input
                    type='select'
                    data-test={`${name}-select`}
                    options={extractedOptions}
                    label={tr(translationKey, defaultLabel)}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                    menuPlacement='top'
                  />
                </CheckoutInputWrapper>
              )
            }

            return (
              <CheckoutInputWrapper key={index}>
                <Input
                  type={sensitive ? 'password' : 'text'}
                  data-test={`${name}-input`}
                  label={tr(translationKey, defaultLabel)}
                  {...formValues.registerInput(name)}
                  required={required}
                  hideErrorLine
                  autoComplete='off'
                />
              </CheckoutInputWrapper>
            )
          })
        : null}
    </>
  )
}
