import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ThunkDispatch } from 'redux-thunk'
import { MenuContainer, MenuDropDown, MenuWrapper } from './LanguageMenu.styled'

import settings from '../../settings'
import { actions } from 'mmfintech-checkout-commons'
import { initializeLanguage, languages, useDropDownMenu } from 'mmfintech-commons'

import ArrowUpIcon from '../../assets/icons/arrow-up.svg?react'
import ArrowDownIcon from '../../assets/icons/arrow-down.svg?react'

export const LanguageMenu = ({ onChange }) => {
  const { selectedLanguage } = useSelector(({ common: { selectedLanguage } }) => ({ selectedLanguage }), shallowEqual)

  const [filteredLanguages, setFilteredLanguages] = useState([])

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()
  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const selectLanguage = (languageCode: string) => {
    if (languageCode !== selectedLanguage) {
      void dispatch(actions.common.selectLanguage(languageCode))
      typeof onChange === 'function' && onChange(languageCode)
    }
    hideDropDown()
    initializeLanguage(languageCode)
  }

  const getLanguageName = () => {
    const data = filteredLanguages.find(item => item.code === selectedLanguage)
    return data ? data.name : ''
  }

  const handleDropDownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e && e.stopPropagation()
    toggleDropDown()
  }

  useEffect(() => {
    const filtered = settings.languages.map(code => languages.find(v => v.code === code)).filter(v => v != null)
    setFilteredLanguages(filtered)
    // eslint-disable-next-line
  }, [])

  if (!selectedLanguage) {
    return null
  }

  return (
    <MenuWrapper data-test='language-menu-wrapper'>
      <MenuContainer className={cn({ opened: visible })} onClick={handleDropDownClick}>
        <span className='selected'>{getLanguageName()}</span>
        <span className='arrow'>{visible ? <ArrowUpIcon /> : <ArrowDownIcon />}</span>
      </MenuContainer>
      <MenuDropDown className={cn({ opened: visible })} data-test='language-menu-content'>
        <ul data-test='language-menu-list'>
          {filteredLanguages?.map(v => (
            <li key={v.code} onClick={() => selectLanguage(v.code)} data-test='language-menu-item'>
              {v.name}
            </li>
          ))}
        </ul>
      </MenuDropDown>
    </MenuWrapper>
  )
}
