import { shallowEqual, useSelector } from 'react-redux'

import { CheckoutBody } from './Checkout.styled'
import { ErrorDisplay } from 'mmfintech-portal-commons'

export const InternalExecutionStep = () => {
  const { checkoutError } = useSelector(
    ({ checkout: { checkoutError } }) => ({
      checkoutError
    }),
    shallowEqual
  )

  return (
    <CheckoutBody>
      <ErrorDisplay error={checkoutError} />

      {/* TODO: <InternalPayment session={checkoutPay} /> */}
    </CheckoutBody>
  )
}
