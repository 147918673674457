export const SuccessIcon = ({ stroke = '#7cc670' }) => (
  <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.78223 7.7285C8.30558 7.20515 8.85076 6.70766 9.41575 6.23703C12.9427 3.2991 17.2416 1.40775 21.8223 0.804688'
      stroke={stroke}
      strokeWidth='1.5'
    />
    <path
      d='M27.9228 0.795378C32.8569 1.42854 37.4887 3.55967 41.1858 6.91423C45.1557 10.5162 47.826 15.3275 48.7828 20.6018C49.7395 25.8762 48.9291 31.3188 46.4773 36.0857C44.0255 40.8525 40.0693 44.6771 35.2223 46.9663C30.3753 49.2556 24.9084 49.8815 19.6694 48.747C14.4304 47.6125 9.71221 44.781 6.24649 40.6916C2.78076 36.6023 0.761244 31.4836 0.501142 26.1295C0.251912 20.9992 1.63142 15.9275 4.43311 11.6362'
      stroke={stroke}
      strokeWidth='1.5'
    />
    <path
      d='M17.2051 24.2989L22.7246 29.8184L32.4648 20.0781'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M42.2076 24.948C42.2076 28.7361 40.9576 32.4182 38.6516 35.4235C36.3456 38.4287 33.1124 40.5891 29.4535 41.5695C25.7945 42.5499 21.9143 42.2956 18.4146 40.846C14.915 39.3963 11.9914 36.8324 10.0974 33.5519C8.20339 30.2714 7.44477 26.4576 7.93921 22.702C8.43364 18.9463 10.1535 15.4588 12.832 12.7803C15.5106 10.1017 18.9981 8.38189 22.7537 7.88745C26.5093 7.39301 30.3232 8.15163 33.6037 10.0456'
      stroke={stroke}
      strokeWidth='1.5'
    />
  </svg>
)
