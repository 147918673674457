export const ArrowRight = ({ color = 'white' }) => (
  <svg width='40px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 12H20M22 12L13 7M22 12L13 17'
      stroke={color}
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
