import { useEffect } from 'react'

import { Spinner } from 'mmfintech-portal-commons'
import { BackIcon } from '../../icons'
import { SelectBankItem } from '../SelectBankItem'
import { BanksLoadingWrapper, BanksWrapper } from './SelectBank.styled'

import { isBase64EncodedImage, tr, useDelayedExecute } from 'mmfintech-commons'
import { configuration, extractBankName, extractBankNameDomestic } from 'mmfintech-checkout-commons'

import { SupportedBank } from 'mmfintech-commons-types'
import { UseFilteredBanksResult } from 'mmfintech-checkout-commons'

import BankIcon from '../../assets/icons/bank-icon.svg'

type SelectBankProps = {
  banks?: UseFilteredBanksResult
  selected?: string
  loading?: boolean
  logEvent?: (eventType: string, reference?: string, storeOnlyOnce?: boolean) => void
  handleSubmit?: () => void
}

export const SelectBank = ({ banks, selected, loading, logEvent, handleSubmit }: SelectBankProps) => {
  const delayedLog = useDelayedExecute({
    name: 'delayedEventLogging',
    onExecute: () => {
      logEvent('banks_scrolling', '', true)
    }
  })

  const handleScroll = () => {
    delayedLog.execute()
    // todo -- това не работи
    // if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
    //   logEvent('banks_scrolled_to_bottom', '', true)
    // }
  }

  const prepareIcon = (bank: SupportedBank) => {
    if (bank.logoUrl) {
      return (
        <img
          src={
            isBase64EncodedImage(bank.logoUrl) || bank.logoUrl.substring(0, 4) === 'http'
              ? bank.logoUrl
              : configuration.readBackendConfig() + bank.logoUrl
          }
          alt=''
        />
      )
    }

    return <img src={BankIcon} alt='' />
    // return Array.isArray(bank.options) && bank.options.length ? null : null
  }

  useEffect(() => {
    // noinspection JSUnresolvedReference
    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line
  }, [])

  return (
    <BanksWrapper>
      {loading ? (
        <BanksLoadingWrapper>
          <Spinner />
        </BanksLoadingWrapper>
      ) : null}

      {banks.hasBack ? (
        <div className='back-icon' onClick={banks.handleBack} data-test='bank-button'>
          <BackIcon /> {tr('FRONTEND.BUTTONS.BACK', 'Back')}
        </div>
      ) : null}

      {Array.isArray(banks.banks) && banks.banks.length ? (
        <div className='bank-selection'>
          {banks.banks.map(bank => {
            const { bankChoiceId, underMaintenance } = bank
            return (
              <SelectBankItem
                key={bankChoiceId}
                caption={extractBankName(bank)}
                description={extractBankNameDomestic(bank)}
                selected={selected === bankChoiceId}
                maintenance={underMaintenance}
                onClick={() => banks.handleSelectBank(bank)}
                icon={prepareIcon(bank)}
                onSubmit={handleSubmit}
                data-test={`bank-${bankChoiceId}`}
              />
            )
          })}
        </div>
      ) : !banks.treeMode ? (
        <div className='error-text'>
          {banks.bankSearchText?.length > 2
            ? tr('CHECKOUT.PAYMENT.NO_BANKS_FOUND', 'No banks found')
            : tr('CHECKOUT.PAYMENT.SEARCH_BANK_HINT', 'Type 3 or more characters to filter banks…')}
        </div>
      ) : null}
    </BanksWrapper>
  )
}
