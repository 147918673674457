import styled from 'styled-components'

export const BanksWrapper = styled.div`
  overflow-y: auto;
  margin-bottom: 2rem;

  .back-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2rem;
    cursor: pointer;

    color: #b4bfcb;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;

    svg {
      margin-right: 1rem;
    }
  }

  .error-text {
    padding: 1rem 2rem 3rem;

    color: #b4bfcb;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
  }

  @media (min-width: 500px) {
    .back-icon {
      padding: 0 4rem;
    }
    .error-text {
      padding: 1rem 4rem 3rem;
    }
  }
`

export const BanksLoadingWrapper = styled.div`
  padding: 2rem 0;
`
