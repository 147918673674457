import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { PaymentMethodButton } from '../PaymentMethodButton'
import { SelectMethodContainer, SelectMethodEmpty, SelectMethodTitle, SelectMethodWrapper } from './SelectMethod.styled'

import { isValidArray, tr } from 'mmfintech-commons'

type SelectMethodProps = {
  method: string
  setMethod: (method: string) => void
  errorVisible?: boolean
}

export const SelectMethod = ({ method, setMethod, errorVisible }: SelectMethodProps) => {
  const { checkoutOptions } = useSelector(
    ({ checkout: { checkoutOptions } }) => ({
      checkoutOptions
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(checkoutOptions) && checkoutOptions.length === 1) {
      setMethod(checkoutOptions[0].paymentMethod)
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  if (Array.isArray(checkoutOptions) && checkoutOptions.length > 1) {
    return (
      <SelectMethodWrapper>
        <SelectMethodTitle>{tr('CHECKOUT.PAYMENT.METHOD_LABEL', 'Select payment method')}</SelectMethodTitle>
        <SelectMethodContainer>
          {checkoutOptions.map(option => (
            <PaymentMethodButton
              key={option.paymentMethod}
              name={option.paymentMethod}
              selected={option.paymentMethod === method}
              onClick={() => setMethod(option.paymentMethod)}
              data-test={`method-${option.paymentMethod}`}
            />
          ))}
        </SelectMethodContainer>
      </SelectMethodWrapper>
    )
  }

  if (!isValidArray(checkoutOptions) && errorVisible) {
    return (
      <SelectMethodWrapper>
        <SelectMethodEmpty data-test='no-methods-error'>
          {tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}
        </SelectMethodEmpty>
      </SelectMethodWrapper>
    )
  }

  if (method && errorVisible) {
    return (
      <SelectMethodWrapper>
        <SelectMethodTitle>
          {tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay with {{METHOD}}', {
            METHOD: tr('METADATA.PAYMENT_OPTIONS.' + method, method)
          })}
        </SelectMethodTitle>
      </SelectMethodWrapper>
    )
  }

  return null
}
