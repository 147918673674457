import styled from 'styled-components'

export const ContentWrapperStyled = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  font-family: Roboto, sans-serif;
  background-color: #f6f7f8;
`
