export const ErrorIcon = () => (
  <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M21 31L31 21' stroke='#F16969' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21 21L31 31' stroke='#F16969' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M21.1844 8.77667C24.9409 7.77011 28.9246 8.03122 32.5176 9.51949C36.1106 11.0078 39.1121 13.64 41.0566 17.008C43.0012 20.376 43.78 24.2916 43.2724 28.1473C42.7648 32.0031 40.9991 35.5836 38.2491 38.3336C35.4991 41.0835 31.9186 42.8493 28.0628 43.3569C24.2071 43.8645 20.2915 43.0857 16.9235 41.1411C13.5555 39.1966 10.9233 36.1951 9.435 32.6021C7.94673 29.0091 7.68562 25.0254 8.69218 21.2689'
      stroke='#F16969'
      strokeWidth='1.5'
    />
    <path
      d='M34.994 49.3333C32.2031 50.4098 29.1705 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26C51 28.922 50.4987 31.7268 49.5775 34.3333'
      stroke='#F16969'
      strokeWidth='1.5'
    />
  </svg>
)
